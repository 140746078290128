<template>
  <div class="order-history">
    <div class="header">
      <div class="title">
        <h2>Order History</h2>

        <button class="close" aria-label="close" @click="close()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
          >
            <path
              fill="currentColor"
              id="x"
              d="M26,7.1,23.9,5l-8.4,8.4L7.1,5,5,7.1l8.4,8.4L5,23.9,7.1,26l8.4-8.4L23.9,26,26,23.9l-8.4-8.4Z"
              transform="translate(-5 -5)"
              fill-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="search">
        <b-input-group>
          <b-form-input
            v-model="filter"
            class="form-control"
            placeholder="Search Orders"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <div class="table-overflow">
      <b-table
        show-empty
        stacked="md"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >

        <template #cell(orderDetails)="data">
          <button
            class="button"
            @click="info(data.item, data.index, $event.target)"
          >
            view order
          </button>
        </template>
      </b-table>
    </div>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0 pagination"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      hide-header
      hide-footer
      modal-class="info-modal"
      body-class="info-history"
      v-modal="infoModal.show"
      ok-only
      @hide="resetInfoModal"
    >
      <div class="info-modal">
        <div class="header">
          <h2>Order Details: {{ infoModal.content.orderNo }}</h2>
          <button
            class="close"
            aria-label="close"
            @click.prevent="$bvModal.hide(infoModal.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
            >
              <path
                fill="currentColor"
                id="x"
                d="M26,7.1,23.9,5l-8.4,8.4L7.1,5,5,7.1l8.4,8.4L5,23.9,7.1,26l8.4-8.4L23.9,26,26,23.9l-8.4-8.4Z"
                transform="translate(-5 -5)"
                fill-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div class="body">
          <p><span>Order Date:</span> {{ infoModal.content.orderDate }}</p>
          <p><span>Recipient:</span> {{ infoModal.content.recipient }}</p>
          <p><span>Address:</span> {{ infoModal.content.shipAddress }}</p>
          <p><span>Product Name:</span> {{ infoModal.content.prodName }}</p>
          <p><span>Product id:</span> #{{ infoModal.content.prodId }}</p>
          <p><span>Sentiment:</span> {{ infoModal.content.sentiment }}</p>
          <p><span> Message:</span> {{ infoModal.content.personalMsg }}</p>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'OrderHistory',
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'orderDate',
          label: 'Order Date',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'orderNo', label: 'Order #', sortable: false },
        { key: 'prodName', label: 'Product Name', sortable: false },
        { key: 'sender', label: 'Sender Profile', sortable: true },
        { key: 'recipient', label: 'Recipient Name', sortable: true },
        { key: 'shipDate', label: 'Scheduled Ship', sortable: true },
        { key: 'orderDetails', label: 'View Order', formatter: 'viewOrder' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
        show: false,
      },
    };
  },
  computed: {
    ...mapState('front', ['orderHistory']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.$gtm.trackView('Order History', '');
  },
  methods: {
    close() {
      this.$emit('close');
    },
    info(item, index, button) {
      this.infoModal.title = `${item.orderNo}: ${item.sender}`;
      this.infoModal.content = item;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = '';
      this.infoModal.content = '';
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateChart(details) {
      this.items = details;
      this.totalRows = details.length;
    },

    triggerUpdateChart() {
      this.updateChart(this.getOrderHistoryData(this.orderHistory));
    },

    getOrderHistoryData(orderDetails) {
      const groupOrders = [];
      const orderedProducts = orderDetails || [];

      orderedProducts.forEach((order) => {
        const shipments = order.data.shipments?.[0] || [];
        const shippingAddress = shipments?.shipping_address || [];
        const prodItems = order.data.product_items || [];
        const shipDate = order.data.shipments[0].c_req_ship_date.split('-');
        const address = `${shippingAddress.address1}${
          shippingAddress?.address2 ? ` ${shippingAddress.address}` : ''
        }, ${shippingAddress.city}, ${shippingAddress.state_code}, ${
          shippingAddress.postal_code
        }`;
        prodItems.forEach((prodItem) => {
          groupOrders.push({
            orderDate: this.formatUTCtoLocal(order.data.creation_date),
            orderNo: order.data.order_no,
            prodName: prodItem.product_name,
            prodId: prodItem.product_id,
            sender: order.data.c_senderProfile,
            recipient: order.data.shipments[0].shipping_address.full_name,
            shipAddress: address,
            shipDate: shipDate[1] + '/' + shipDate[2] + '/' + shipDate[0],
            status: order.data.status,
            sentiment: prodItem.c_sentiment,
            personalMsg: prodItem.c_hbc_personalizationMessage,
          });
        });
      });

      return groupOrders;
    },
    formatUTCtoLocal(utc) {
      return new Date(utc)?.toLocaleDateString();
    },
  },

  watch: {
    orderHistory() {
      this.triggerUpdateChart();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '../scss/variables';

.order-history,
.info-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: $base-white;
  border-radius: 3px;
  gap: 0.6rem;
  overflow: hidden;
  margin: auto;
  margin-top: 1.2rem;

  .header {
    display: flex;
    flex-direction: column;
    background-color: var(--base-light);
    padding: 0.6rem 0;
    gap: 0.6rem;
    width: -webkit-fill-available;

    .title {
      display: flex;
      justify-content: space-between;
      padding: 0 0.6rem;

      h2 {
        font: $font-size-21;
        font-weight: bold;
        color: $base-black;
        margin-bottom: 0;
      }

      .close {
        background: transparent;
        color: var(--base-black);
      }
    }
    .search {
      padding: 0 0.6rem;
    }
    .input-group {
      max-width: 480px;
    }
  }
  .table-overflow {
    overflow: auto;
    max-width: 98%;
    width: fit-content;
  }
  .pagination {
    border: 2px solid var(--base-grey);
    gap: 0.125rem;
  }
}
::v-deep .page-item {
  .page-link {
    font: $font-size-18;
    font-weight: normal;
    padding: 0.2rem 0.4rem;
    background-color: var(--base-button);
    color: var(--base-black);
    border: 1px solid var(--border);
  }
  &.active .page-link {
    background-color: var(--highlight);
    color: #373534;
    font-weight: bold;
  }
}
::v-deep .table {
  th {
    font: $font-size-16;
    font-weight: bold;
    padding: 0.6rem;
    white-space: nowrap;
    border-top: none;
    border-bottom: 2px solid var(--base-grey);
  }
  td {
    font: $font-size-14;
    padding: 0.6rem;
  }
  th,
  td {
    color: var(--base-black);
  }
}
.button {
  border-radius: 2px;
  font: $font-size-15;
  padding: 0.6rem;
  color: #373534;
  background-color: $base-yellow;
  border: $base-yellow;
  min-width: 120px;
  text-transform: capitalize;

  @media (max-width: $tablet-width) {
    min-width: 80px;
  }
}

::v-deep .modal-body {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

::v-deep .modal-content {
  border-radius: 0 !important;
  border-color: transparent;
  background-color: transparent;
}

::v-deep .modal-dialog {
  max-width: none;
  margin: 0;
}
.info-modal {
  background-color: $base-white;
  border-radius: 3px;
  padding: 0;
  overflow: hidden;
  width: clamp(720px, 40% + 20vw, 970px);
  margin: unset;
  margin-top: 2.4rem;

  .header {
    background-color: $base-light;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.2rem;

    h2 {
      font: $font-size-21;
      font-weight: bold;
      color: $base-black;
      margin-bottom: 0;
    }

    .close {
      background: transparent;
      color: $base-black;
      font-size: 200;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1.2rem;
    margin-bottom: 1.2rem;
  }

  p {
    font: $font-size-14;
    color: $base-black;
    margin-bottom: 0rem;
    span {
      font: $font-size-16;
      text-transform: uppercase;
      font-weight: 400;
      margin-right: 0.4rem;
    }
  }
}
</style>
